import { handleActions } from 'redux-actions';

const header = handleActions({
    ['SET/HEADER/SELECTEDKEYS'](state, action) {
        return { ...state, selectedKeys: action.selectedKeys, };
    },
    ['SET/HEADER/SELECTEDTITLE'](state, action) {
        return { ...state, selectedTitle: action.selectedTitle, };
    },
    ['SET/HEADER/HOVERTITLE'](state, action) {
        return { ...state, hoverTitle: action.hoverTitle, };
    },
    ['SET/HEADER/VERSION'](state, action) {
        return { ...state, version: action.version, };
    }
}, {
        selectedKeys: [],
        selectedTitle: '首页',
        hoverTitle:'',
        version:false
    });

export default header;