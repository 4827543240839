import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Col, Input } from 'antd'
import styles from './header.less'
import HeaderTitle from './HeaderTitle'
import FocusInput from './FocusInput'
import logo from '../images/logo.png'
import cxj_logo from '../images/cxj_logo_bg_red.png'

class Header extends React.Component {
    render() {
        const newsmenudata = [{ title: '多多观察', link: '/news/observation' }, { title: '媒体报道', link: '/news/report' }]
        const { selectedTitle, hoverTitle,version } = this.props.header
        const titlebool = selectedTitle != '车小集'
        return (
            <div style={{'display':version?'block':'none'}}>
                <Row type="flex" justify="center" className={styles.border}>
                    <Col span={2}></Col>
                    <Col span={20}>
                        <Row type="flex">
                            <Col span={3}>
                                <div className={styles.logo}>
                                    {titlebool?
                                        <img src={logo} alt="" />:
                                        <img src={cxj_logo} style={{width:'100%'}} alt="" />
                                    }
                                </div>
                            </Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                                <HeaderTitle title="首页" link="/index" hasdropdown={false} selected={selectedTitle === "首页" || hoverTitle === "首页"} />
                            </Col>
                            <Col span={3}>
                                <HeaderTitle title="品牌" link="/brand" hasdropdown={false} selected={selectedTitle === "品牌" || hoverTitle === "品牌"} /> 
                            </Col>
                            <Col span={3}>
                                <HeaderTitle title="新闻中心" link="/news/observation" hasdropdown={true} selected={selectedTitle === "新闻中心" || hoverTitle === "新闻中心"} menudata={newsmenudata} />                                
                            </Col>
                            {/* <Col span={3}>
                                <HeaderTitle title="车小集" link="/cxj" hasdropdown={false} selected={selectedTitle === "车小集" || hoverTitle === "车小集"} /> 
                            </Col> */}
                            <Col span={3}>
                                <HeaderTitle title="联系我们" link="/contact" hasdropdown={false} selected={selectedTitle === "联系我们" || hoverTitle === "联系我们"} /> 
                            </Col>
                            <Col span={3}>
                                <FocusInput isSearchPage={false} history={this.props.history} titlebool={titlebool} />
                            </Col>
                            <Col span={2}>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2}></Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);