import axios from 'axios'

axios.interceptors.request.use(function (config) {
    
    return config
})

axios.interceptors.response.use(function (config) {
    
    return config
})