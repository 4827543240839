import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeheaderversion, changeselectedtitle } from '../../action/index/header'
import { changefooterversion } from '../../action/index/footer'

import no_result from '../images/no_result.png'
import styles from './search.less'



class Noresult extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(false)
        this.props.changefooterversion(false)
        // this.props.changeselectedtitle('联系我们')
    }
    onClick(){
        this.props.history.goBack()
    }
    render() {

        return (
            <div style={{ height: document.body.clientHeight }}>
                <div className={styles.search}>
                    <div className={styles.noresult}>
                        <div className={styles.text}>
                            很抱歉，您所查找的内容不存在！
                        </div>
                        <div>
                            <img src={no_result} alt="" />
                        </div>
                        <div className={styles.goback} onClick={this.onClick.bind(this)}>
                            返回
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Noresult)