exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".inputbox {\n  position: relative;\n  top: calc(50% - 12px);\n  text-align: center;\n}\n.inputbox .btnbox {\n  position: absolute;\n  right: calc(50% - 50px);\n  cursor: pointer;\n}\n.inputbox .btnbox img {\n  width: 16px;\n}\n.inputbox .cut_off {\n  position: absolute;\n  height: 16px;\n  top: calc(50% - 8px);\n  right: calc(50% - 30px);\n  border-left: 1px solid #c5c5c5;\n}\n.inputbox .searchinput {\n  width: 128px;\n  height: 24px;\n  border: 1px solid #b5b5b5;\n  border-radius: 12px;\n}\n.inputbox_large {\n  position: relative;\n  top: calc(50% - 12px);\n  text-align: center;\n}\n.inputbox_large .btnbox {\n  position: absolute;\n  right: 48px;\n  top: 8px;\n  cursor: pointer;\n}\n.inputbox_large .btnbox img {\n  width: 30px;\n  height: 34px;\n}\n.inputbox_large .cut_off {\n  position: absolute;\n  height: 36px;\n  top: calc(50% - 18px);\n  right: 120px;\n  border-left: 2px solid #c5c5c5;\n}\n.inputbox_large .searchinput {\n  width: 730px;\n  height: 52px;\n  border: 1px solid #898989;\n  border-radius: 6px;\n  font-size: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"inputbox": "inputbox",
	"btnbox": "btnbox",
	"cut_off": "cut_off",
	"searchinput": "searchinput",
	"inputbox_large": "inputbox_large"
};