import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './imgmask.less'


class ImgChange extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hover: false }
    }
    onhover(bool) {
        this.setState({ hover: bool })
    }
    onClick(){
        this.props.onClick()
    }
    render() {
        const { normalsrc, selectedsrc } = this.props
        const { hover } = this.state
        const normalr = <div onMouseEnter={this.onhover.bind(this, true)} onClick={this.onClick.bind(this)}><img src={normalsrc} alt="" /></div>
        const hoverr = <div onMouseLeave={this.onhover.bind(this, false)} onClick={this.onClick.bind(this)}><img src={selectedsrc} alt="" /></div>

        return (
            <div className={styles.imgbox}>
                {hover ? hoverr : normalr}
            </div>
        )
    }
}
ImgChange.propTypes = {
    onClick: PropTypes.func
}


export default connect()(ImgChange);