import React from 'react'
import { Carousel } from 'antd'
import PropTypes from 'prop-types'
import styles from './carousels.less'

class Carousels extends React.Component {
    render() {
        const { data, autoplay } = this.props
        return (
            <div>
                <Carousel autoplay={autoplay}>
                    {data.map((i,k)=>{
                        return <img src={i} key={k} alt=""/>
                    })}
                </Carousel>
            </div>
        )
    }
}
Carousels.propTypes = {
    data: PropTypes.array,
    autoplay: PropTypes.bool,
    boxstyle: PropTypes.string
}
Carousels.defaultProps = {
    data: [<h1>1</h1>, <h1>2</h1>, <h1>3</h1>, <h1>4</h1>],
}
export default Carousels