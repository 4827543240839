import React from 'react'
import { connect } from 'react-redux'
import styles from './footer.less'
import footer_bear from '../images/footer_bear.png'
import bear_code from '../images/bear_code.png'
import bear_phone_new from '../images/bear_phone_new.png'
import footer_bear_border from '../images/footer_bear_border.png'
import bear_code_border from '../images/bear_code_border.png'
import bear_phone_border from '../images/bear_phone_border.png'

class BearsShow extends React.Component {
    render() {
        const { selectedIcon } = this.props.footer
        const { hasborder } = this.props
        return (
            <div>
                {selectedIcon === '' || selectedIcon === 'sina' ? 
                    <img src={hasborder?footer_bear:footer_bear_border} alt=""/> :
                    selectedIcon === 'wechat'?
                        <span className={styles.codeshow}><img src={hasborder?bear_code:bear_code_border} alt="" /></span> :
                        <span className={styles.phoneshow}><img src={hasborder?bear_phone_new:bear_phone_border} alt="" /></span>
                }
            </div>
        )
    }
}
function mapStateToProps({ footer }) {
    return {
        footer: footer
    };
}

export default connect(mapStateToProps)(BearsShow);