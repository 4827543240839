// header
// 设置header显示隐藏
export function changeheaderversion(version) {
    return {
        type: 'SET/HEADER/VERSION',
        version: version,
    }
}
// 设置选中下拉选项
export function changeselectedkeys(selectedKeys) {
    return {
        type: 'SET/HEADER/SELECTEDKEYS',
        selectedKeys: selectedKeys,
    }
}
// 设置选中Title
export function changeselectedtitle(selectedTitle) {
    return {
        type: 'SET/HEADER/SELECTEDTITLE',
        selectedTitle: selectedTitle,
    }
}
// 设置划入Title
export function changehovertitle(hoverTitle) {
    return {
        type: 'SET/HEADER/HOVERTITLE',
        hoverTitle: hoverTitle,
    }
}