import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import { changeselectedkeys, changeselectedtitle, changehovertitle } from '../../action/index/header';
import styles from './headertitle.less'
import dropdownImg from '../images/dropdown.png'

const MenuItem = Menu.Item

class HeaderTitle extends React.Component {
    // 下拉选择
    change(title, e) {
        this.changetitle(title)
        this.props.changeselectedkeys([e.key])
    }
    changetitle(title,key){
        this.props.changeselectedkeys([])
        this.props.changeselectedtitle(title)
        if (key){
            this.props.changeselectedkeys([key.title])
        }
    }
    // 划入
    hovertitle(bool,title){
        if(bool){
            this.props.changehovertitle(title)
        }else{
            this.props.changehovertitle('')
        }
    }
    // 设置有下拉框
    setDropDown () {
        const { menudata, selected, title, link } = this.props
        const { selectedKeys } = this.props.header;
        const menuitems = menudata.map((i, k) => {
            return (<MenuItem key={i.title} onMouseEnter={this.hovertitle.bind(this, true, title)} onMouseLeave={this.hovertitle.bind(this, false, title)}><Link to={i.link}>{i.title}</Link></MenuItem>)
        })
        const menu = <Menu selectedKeys={selectedKeys} onClick={this.change.bind(this, title)}>{menuitems}</Menu>
        const noselectedr = 
            <Dropdown overlay={menu} placement="bottomCenter" onClick={this.changetitle.bind(this, title, menudata[0])} onMouseEnter={this.hovertitle.bind(this, true, title)}>
                <div className={styles.normal_title}>
                    <Link to={link}><span>{title}</span></Link>
                </div>
            </Dropdown>
        const selectedr = 
            <Dropdown overlay={menu} placement="bottomCenter" onClick={this.changetitle.bind(this, title, menudata[0])} onMouseLeave={this.hovertitle.bind(this, false, title)}>
                <div className={styles.selected_title}>
                    <Link to={link}>
                        <span>
                            {title}
                            <span className={styles.redborder}></span>
                        </span>
                        {/* <div className={styles.titleimgbox}>
                            <img src={dropdownImg} alt="" />
                        </div> */}
                    </Link>
                </div>   
            </Dropdown>
        const r = selected ? selectedr : noselectedr
        return r
    }
    // 无下拉框
    noDropDown () {
        const { selected, title, link } = this.props
        const selectedr = 
            <Link to={link}>
                <div className={styles.selected_title} onClick={this.changetitle.bind(this, title)} onMouseLeave={this.hovertitle.bind(this, false, title)}>
                    <span>
                        {title}
                        <span className={styles.redborder}></span>
                    </span>
                    {/* <div className={styles.titleimgbox}>
                        <img src={dropdownImg} alt=""/>
                    </div> */}
                </div>
            </Link>
        const noselectedr = 
            <Link to={link}>
                <div className={styles.normal_title} onClick={this.changetitle.bind(this, title)} onMouseEnter={this.hovertitle.bind(this, true, title)}>
                    <span>{title}</span>
                </div>
            </Link>
        const r = selected ? selectedr : noselectedr
        return r
    }
    render() {
        const { hasdropdown } = this.props
        
        return (
            <div className={styles.headertitle}>
                {hasdropdown ? this.setDropDown() : this.noDropDown()}
            </div>
        )
    }
}
HeaderTitle.propTypes = {
    menudata: PropTypes.array,
    selectedKeys: PropTypes.array,
    selected: PropTypes.bool,
    hasdropdown: PropTypes.bool,
    title: PropTypes.string,
    link: PropTypes.string
}

function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeselectedkeys: bindActionCreators(changeselectedkeys, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changehovertitle: bindActionCreators(changehovertitle, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTitle);