exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sidebar {\n  font-size: 18px;\n  font-family: MicrosoftYaHeiLight;\n  font-weight: bold;\n}\n.sidebar .redbar {\n  display: inline-block;\n  width: 35px;\n  height: 4px;\n  border-radius: 2px;\n  background: #BD081C;\n  position: relative;\n  top: -10px;\n}\n.sidebar .selected {\n  color: #fff;\n}\n.sidebar .normal {\n  color: #969595;\n}\n", ""]);

// exports
exports.locals = {
	"sidebar": "sidebar",
	"redbar": "redbar",
	"selected": "selected",
	"normal": "normal"
};