import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeheaderversion } from '../../action/index/header'
import { changefooterversion } from '../../action/index/footer'
import Carousels from '../common/Carousels'

import banner_one from '../images/banner_one.png'
import banner_two from '../images/banner_two_1.png'
import banner_three from '../images/banner_three.png'



class Index extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(true)
        this.props.changefooterversion(true)
    }
    render() {
        const data = [
            banner_two,
            banner_one,
            banner_three
        ]

        return (
            <div>
                <Carousels data={data} autoplay={true} />
            </div>
        )
    }
}
function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index)