var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".news {\n  background-image: url(" + escape(require("../images/news_bg.png")) + ");\n  background-size: 100% 100%;\n}\n.newscontent {\n  margin-top: 220px;\n  margin-bottom: 110px;\n}\n.newscontent .newssidebar {\n  left: 40px;\n}\n.newscontent .box > div {\n  margin-bottom: 32px;\n}\n", ""]);

// exports
exports.locals = {
	"news": "news",
	"newscontent": "newscontent",
	"newssidebar": "newssidebar",
	"box": "box"
};