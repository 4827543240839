import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { changeheaderversion, changeselectedtitle } from '../../action/index/header'
import { changefooterversion } from '../../action/index/footer'
import FocusInput from '../common/FocusInput'
import SearchResult from '../common/SearchResult'
import search_logo from '../images/search_logo.png'
import goback from '../images/goback.png'
import styles from './search.less'


class Search extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(false)
        this.props.changefooterversion(false)
    }
    onClick(){
        this.props.changeselectedtitle('首页')
    }
    render() {
        const {result} = this.props.search
        return (
            <div style={{ height: document.body.clientHeight }}>
                <div className={styles.search}>
                    <div className={styles.search_box}>
                        <div className={styles.search_logo_box}>
                            <img src={search_logo} alt="" />
                        </div>
                        <div className={styles.search_input_box}>
                            <FocusInput isSearchPage={true} history={this.props.history}/>
                        </div>
                        <div className={styles.search_result_box}>
                            <SearchResult result={result} />
                        </div>
                        <div className={styles.goback_box}>
                            <Link to='/index' onClick={this.onClick.bind(this)}><img src={goback} alt="" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps({ search }) {
    return {
        search: search,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Search)