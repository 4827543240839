import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './sidebar.less'


class Sidebar extends React.Component {
    onClick(title) {
        this.props.onClick(title)
    }
    render() {
        const { menudata, selectedtitle, fixed, selectall } = this.props
        const redbar = <div className={styles.redbar}></div>
        const nofixedr = menudata.map((i,k)=>{
            if (selectall){
                return <div key={k}>
                    <div className={styles.selected}>{i}</div>
                    {redbar}
                </div>
            }
            if(i===selectedtitle){
                return <div key={k} style={{ cursor: 'pointer' }} onClick={this.onClick.bind(this,i)}>
                    <div className={styles.selected}>{i}</div>
                    {redbar}
                </div>
            }else{
                return <div key={k} style={{ cursor: 'pointer' }} onClick={this.onClick.bind(this, i)}>
                    <div className={styles.normal}>{i}</div>
                    {redbar}
                </div>
            }
        })
        const fixedr = menudata.map((i, k) => {
            return <div key={k}>
                <div>{i}</div>
                {redbar}
            </div>
        })
        return (
            <div className={styles.sidebar}>
                {fixed?fixedr:nofixedr}
            </div>
        )
    }
}
Sidebar.propTypes = {
    menudata: PropTypes.array,
    selectedtitle: PropTypes.string,
    fixed: PropTypes.bool,
    selectall: PropTypes.bool,
    onClick: PropTypes.func
}


export default connect()(Sidebar);