import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import SelectedIcon from './SelectedIcon'
import BearsShow from './BearsShow'
import styles from './footer.less'
import logo_word from '../images/logo_word.png'
import logo_red_word from '../images/logo_download_selected.png'
import wechat_normal from '../images/wechat_normal.png'
import wechat_selected from '../images/wechat_selected.png'
import phone_normal from '../images/phone_normal.png'
import phone_selected from '../images/phone_selected.png'
import sina_normal from '../images/sina_normal.png'
import sina_selected from '../images/sina_selected.png'
import wechat_red_normal from '../images/wechat_red_normal.png'
import wechat_red_selected from '../images/wechat_red_selected.png'
import phone_red_normal from '../images/phone_red_normal.png'
import phone_red_selected from '../images/phone_red_selected.png'
import sina_red_normal from '../images/sina_red_normal.png'
import sina_red_selected from '../images/sina_red_selected.png'

class Footer extends React.Component {
    render() {
        const { version, style, bear_border } = this.props.footer
        const { selectedTitle } = this.props.header
        const stylebool = style=="bg_red"
        const titlebool = selectedTitle != '车小集'
        return (
            <div className={styles.footer} style={{ 'display': version ? 'block' : 'none' }}>
                <Row type="flex" justify="center">
                    <Col span={2}>
                        <Row className={stylebool ? styles.footer_top : styles.footer_top_bg_white}></Row>
                        <Row className={styles.footer_bottom}></Row>
                    </Col>
                    <Col span={20}>
                        <Row className={stylebool ? styles.footer_top : styles.footer_top_bg_white} justify="end">
                            <Col span={12}>
                                <div className={styles.textbox}>
                                    <span>
                                        <img src={stylebool ? logo_word : logo_red_word} alt=""/>
                                    </span>
                                    <span>北京好车多多科技有限公司</span>
                                    {/* <span>隐私政策</span>
                                    <span>服务网点</span>
                                    <span>法律信息</span> */}
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={titlebool ? styles.iconsbox : styles.none}>
                                    <span>
                                        <SelectedIcon name="wechat" src_normal={stylebool ? wechat_normal : wechat_red_normal} src_selected={stylebool ? wechat_selected : wechat_red_selected} />
                                    </span>
                                    <span>
                                        <a href="https://weibo.com/p/1006066593160752/home?from=page_100606&mod=TAB&is_all=1#place" target="_blank">
                                            <SelectedIcon name="sina" src_normal={stylebool ? sina_normal : sina_red_normal} src_selected={stylebool ? sina_selected : sina_red_selected} />
                                        </a>
                                    </span>
                                    <span>
                                        <SelectedIcon name="phone" src_normal={stylebool ? phone_normal : phone_red_normal} src_selected={stylebool ? phone_selected : phone_red_selected} />
                                    </span>
                                    <div className={bear_border ? styles.bear_box : styles.bear_border_box}>
                                        <BearsShow hasborder={bear_border} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.footer_bottom} justify="center">
                            <Col span={24} className={styles.textbox}>
                                <span>Copyright © 2018 cdd.group All Rights Reserved 北京好车多多科技有限公司    <a href="https://beian.miit.gov.cn">京ICP备18022026号-4</a></span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2}>
                        <Row className={stylebool ? styles.footer_top : styles.footer_top_bg_white}></Row>
                        <Row className={styles.footer_bottom}></Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps({footer,header}) {
    return {
        footer: footer,
        header: header
    };
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)