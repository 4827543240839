import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './searchresult.less'


class SearchResult extends React.Component {
    render() {
        const { result } = this.props
        const view = result.length?result.map((i,k)=>{
            return(
                <div key={k} className={styles.result}>
                    <a href={i.link} target={i.target}>
                        {i.title}
                    </a>
                </div>
            )
        }):''
        return (
            <div className={styles.resultbox}>
                {view}
            </div>
        )
    }
}
SearchResult.propTypes = {
    result: PropTypes.array,
    // title: PropTypes.string,
    // fixed: PropTypes.bool,
    // onClick: PropTypes.func
}


export default connect()(SearchResult);