import { handleActions } from 'redux-actions';

const brand = handleActions({
    ['SET/BRAND/VIDEO'](state, action) {
        return { ...state, videovesrion: action.videovesrion };
    },
}, {
        videovesrion: false
    });

export default brand;