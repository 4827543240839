exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".search {\n  background: #FFF;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n}\n.search .search_box {\n  height: 600px;\n  width: 740px;\n  position: absolute;\n  top: calc(50% - 300px);\n  left: calc(50% - 370px);\n  text-align: center;\n}\n.search .search_box .search_logo_box {\n  margin-bottom: 60px;\n}\n.search .search_box .search_logo_box img {\n  width: 190px;\n  height: auto;\n}\n.search .search_box .search_input_box {\n  margin-bottom: 40px;\n}\n.search .search_box .search_result_box {\n  margin-bottom: 40px;\n  min-height: 100px;\n  max-height: 300px;\n}\n.search .noresult {\n  display: inline-block;\n  position: absolute;\n  top: calc(50% - 227.5px);\n  left: calc(50% - 253px);\n}\n.search .noresult .text {\n  font-size: 24px;\n  font-family: Adobe Heiti Std R;\n  font-weight: bold;\n  color: #000000;\n  line-height: 108px;\n  text-align: center;\n}\n.search .noresult .goback {\n  font-size: 24px;\n  font-family: Adobe Heiti Std R;\n  cursor: pointer;\n  font-weight: bold;\n  color: #000000;\n  line-height: 108px;\n  text-align: center;\n}\n", ""]);

// exports
exports.locals = {
	"search": "search",
	"search_box": "search_box",
	"search_logo_box": "search_logo_box",
	"search_input_box": "search_input_box",
	"search_result_box": "search_result_box",
	"noresult": "noresult",
	"text": "text",
	"goback": "goback"
};