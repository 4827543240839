// header
// 设置icon划入
export function changeselectedicon(selectedIcon) {
    return {
        type: 'SET/FOOTER/SELECTEDICON',
        selectedIcon: selectedIcon,
    }
}
// 设置footer显示隐藏
export function changefooterversion(version) {
    return {
        type: 'SET/FOOTER/VERSION',
        version: version,
    }
}
// 设置footer样式
export function changefooterstyle(style) {
    return {
        type: 'SET/FOOTER/STYLE',
        style: style,
    }
}
// 设置footer_bear样式
export function changebearborder(bear_border) {
    return {
        type: 'SET/FOOTER/BEARBORDER',
        bear_border: bear_border,
    }
}