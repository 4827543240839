import { handleActions } from 'redux-actions';

const search = handleActions({
    ['SET/SEARCH/KEYWORD'](state, action) {
        return { ...state, keyword: action.keyword, };
    },
    ['SET/SEARCH/RESULT'](state, action) {
        return { ...state, result: action.result, };
    },
}, {
        keyword: "",
        recruit: ["应聘", "招聘", "面试", "加入", "加入我们", "加入我们", "工作"],
        recruitStr:"应聘、招聘、面试、加入、加入我们、找工作、工作",
        news: ["车多多", "品牌发布", "品牌升级", "车多多郑伟", "郑伟", "车多多蔡波", "蔡波", "车多多李星星", "李星星", "车多多金融", "车多多10年", "二手车", "二手车市场", "二手车金融", "二手车交易", "二手车服务", "二手车新服务", "新服务生态", "资源", "b2b", "b2c", "车", "车多", "多多"],
        newsStr:"车多多、品牌发布、品牌升级、车多多郑伟、郑伟、车多多蔡波、蔡波、车多多李星星、李星星、车多多金融、车多多10年、二手车、二手车市场、二手车金融、二手车交易、二手车服务、二手车新服务、新服务生态、资源、b2b、b2c、车、车多、多多",
        observation: ["新闻", "业内动态", "官方推荐", "精彩", "车展", "新车", "进口车", "国产车", "汽车", "驾驶乐趣", "性能", "品质", "动力", "颜值", "价格", "吉利"],
        observationStr:"新闻、业内动态、官方推荐、精彩、车展、新车、进口车、国产车、汽车、驾驶乐趣、性能、品质、动力、颜值、价格、吉利",
        newsResult:[
            {
                title:'专访：车多多入场，二手车市场即将风云再起',
                link:'http://www.sohu.com/a/239009082_505310',
                target:'_blank'
            },
            {
                title: '车多多COO蔡波：批判的看待二手车市场',
                link: 'http://industry.caijing.com.cn/20180702/4479628.shtml',
                target: '_blank'
            }
        ],
        observationResult: [
            {
                title: '加了拉花的保时捷911限量珍藏款，完美！',
                link: 'https://mp.weixin.qq.com/s/BRtGbMdNKzyZWKVU_lqg7w',
                target: '_blank'
            },
            {
                title: '高智能、越级品质、强动力，9月新车哪款让你心动？',
                link: 'https://mp.weixin.qq.com/s/9pf9RzrN_RUH0mt2VmT29g',
                target: '_blank'
            },
            {
                title: '近4000万的跑车什么样？还没上市就卖完了',
                link: 'https://mp.weixin.qq.com/s/NrCQkKaSSFv1jgXGUnosFQ',
                target: '_blank'
            },
            {
                title: 'BMA架构，7.9s破百，这款新车1.5T预计8万起',
                link: 'https://mp.weixin.qq.com/s/9zWzH8zAzyNca4AtU1zctw',
                target: '_blank'
            }
        ],
        recruitResult: [
            {
                title: '加入我们',
                link: 'https://app.mokahr.com/apply/shuaiche#/?anchorName=007&sourceToken=&_k=y189cc',
                target: '_blank'
            }
        ],
        result:[]
    });

export default search;