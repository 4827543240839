import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeselectedkeys } from '../../action/index/header'
import { Row, Col } from 'antd'
import Sidebar from '../common/Sidebar'
import ImgMask from '../common/ImgMask'
import styles from './news.less'
import news_1 from '../images/news_1.png'
import news_2 from '../images/news_2.png'
import news_3 from '../images/news_3.png'
import news_4 from '../images/news_4.png'




class Observation extends React.Component {
    onClick(key){
        this.props.changeselectedkeys([key])
    }
    render() {
        const data = ['多多观察', '媒体报道']

        return (
            <div className={styles.newscontent}>
                <Row gutter={24}>
                    <Col span={6} className={styles.newssidebar}>
                        <Sidebar menudata={data} selectedtitle='多多观察' onClick={this.onClick.bind(this)} />
                    </Col>
                    <Col span={7} className={styles.box}>
                        <div>
                            <ImgMask Img={news_1} newtab={true} title="加了拉花的保时捷911限量珍藏款，完美！" src="https://mp.weixin.qq.com/s/BRtGbMdNKzyZWKVU_lqg7w" />
                        </div>
                        <div>
                            <ImgMask Img={news_3} newtab={true} title="近4000万的跑车什么样？还没上市就卖完了" src="https://mp.weixin.qq.com/s/NrCQkKaSSFv1jgXGUnosFQ" />
                        </div>
                    </Col>
                    <Col span={7} className={styles.box}>
                        <div>
                            <ImgMask Img={news_2} newtab={true} title="高智能、越级品质、强动力，9月新车哪款让你心动？" src="https://mp.weixin.qq.com/s/9pf9RzrN_RUH0mt2VmT29g" />
                        </div>
                        <div>
                            <ImgMask Img={news_4} newtab={true} title="BMA架构，7.9s破百，这款新车1.5T预计8万起" src="https://mp.weixin.qq.com/s/9zWzH8zAzyNca4AtU1zctw" />
                        </div>
                    </Col>
                    <Col span={4}></Col>
                </Row>
            </div>
        )
    }
}
Observation.propTypes = {
    
}
function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeselectedkeys: bindActionCreators(changeselectedkeys, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Observation)