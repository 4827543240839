import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { changeselectedicon } from '../../action/index/footer'
import styles from './footer.less'


class SelectedIcon extends React.Component {
    changeIcon(icon){
        this.props.changeselectedicon(icon)
    }
    render() {
        const { src_normal, src_selected, name} = this.props
        const { selectedIcon } = this.props.footer
        return (
            <div className={styles.iconbox}>
                <img src={name === selectedIcon ? src_selected : src_normal} onMouseEnter={this.changeIcon.bind(this,name)} onMouseLeave={this.changeIcon.bind(this,'')} alt=""/>
            </div>
        )
    }
}

function mapStateToProps({ footer }) {
    return {
        footer: footer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeselectedicon: bindActionCreators(changeselectedicon,dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedIcon);