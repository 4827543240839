import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { changekeyword, changeresult } from '../../action/search/search';
import { Input } from 'antd'
import styles from './focusinput.less'
import search from '../images/search_btn.png'
import cxj_search from '../images/cxj_search.png'


class FocusInput extends React.Component {
    changeKeyword(e){
        this.props.changekeyword(e.target.value)
    }
    onClick(){
        const {history } = this.props
        const { keyword, recruitStr, newsStr, observationStr, newsResult, observationResult, recruitResult } = this.props.search
        if (!keyword) { return false }
        const recruit_r = recruitStr.indexOf(keyword) != -1
        const news_r = newsStr.indexOf(keyword) != -1
        const observation_r = observationStr.indexOf(keyword) != -1
        if (recruit_r || news_r || observation_r) {
            this.setResult(recruit_r, news_r, observation_r, newsResult, observationResult, recruitResult)
            history.push('/search')
        } else {
            history.push('/noresult')
        }
    }
    onSearch(){
        const { keyword, recruitStr, newsStr, observationStr, newsResult, observationResult, recruitResult } = this.props.search
        if(!keyword){return false}
        const recruit_r = recruitStr.indexOf(keyword) != -1
        const news_r = newsStr.indexOf(keyword) != -1
        const observation_r = observationStr.indexOf(keyword) != -1
        this.setResult(recruit_r, news_r, observation_r, newsResult, observationResult, recruitResult)
    }
    setResult(recruit_r, news_r, observation_r, newsResult, observationResult, recruitResult){
        const { history } = this.props
        let result = []
        if (recruit_r){
            result = result.concat(recruitResult)
        }
        if (news_r) {
            result = result.concat(newsResult)
        }
        if (observation_r) {
            result = result.concat(observationResult)
        }
        if (result.length) {
            this.props.changeresult(result)
        } else {
            history.push('/noresult')
        }
    }

    render() {
        const { keyword } = this.props.search
        const { isSearchPage, titlebool } = this.props
        return (
            <div className={isSearchPage ? styles.inputbox_large : styles.inputbox}>
                <Input className={styles.searchinput} 
                    value={keyword} 
                    onChange={this.changeKeyword.bind(this)}
                    onPressEnter={isSearchPage ? this.onSearch.bind(this) : this.onClick.bind(this)} />
                <span className={styles.btnbox} onClick={isSearchPage ?this.onSearch.bind(this):this.onClick.bind(this)}>
                    <img src={titlebool?search:cxj_search} alt=""/>
                </span>
                <div className={styles.cut_off}></div>
            </div>
        )
    }
}
FocusInput.propTypes = {
    isSearchPage: PropTypes.bool
}
function mapStateToProps({ search }) {
    return {
        search: search,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changekeyword: bindActionCreators(changekeyword, dispatch),
        changeresult: bindActionCreators(changeresult, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FocusInput);