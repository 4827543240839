exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".headertitle {\n  font-size: 18px;\n  font-family: MicrosoftYaHeiLight-Regular, sans-serif;\n  font-weight: bold;\n  line-height: 57px;\n}\n.headertitle a,\n.headertitle a:hover,\n.headertitle a:visited,\n.headertitle a:link {\n  text-decoration: none;\n  color: #000;\n}\n.selected_title {\n  color: #bd081c;\n  position: relative;\n  cursor: pointer;\n  text-align: center;\n}\n.selected_title a,\n.selected_title a:hover,\n.selected_title a:visited,\n.selected_title a:link {\n  text-decoration: none;\n  color: #bd081c;\n}\n.selected_title > span {\n  display: inline-block;\n  height: 57px;\n  position: relative;\n}\n.selected_title > span .redborder {\n  display: inline-block;\n  width: 100%;\n  height: 4px;\n  background: #bd081c;\n  border-radius: 2px;\n  position: absolute;\n  bottom: 0px;\n  left: 0px;\n}\n.selected_title a > span {\n  display: inline-block;\n  height: 57px;\n  position: relative;\n}\n.selected_title a > span .redborder {\n  display: inline-block;\n  width: 100%;\n  height: 4px;\n  background: #bd081c;\n  border-radius: 2px;\n  position: absolute;\n  bottom: 0px;\n  left: 0px;\n}\n.normal_title {\n  position: relative;\n  text-align: center;\n  cursor: pointer;\n  color: #000;\n}\n", ""]);

// exports
exports.locals = {
	"headertitle": "headertitle",
	"selected_title": "selected_title",
	"redborder": "redborder",
	"normal_title": "normal_title"
};