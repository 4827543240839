import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeselectedkeys } from '../../action/index/header'
import { Row, Col } from 'antd'
import Sidebar from '../common/Sidebar'
import ImgMask from '../common/ImgMask'
import styles from './news.less'
import caibo from '../images/caibo.jpg'
import zhengwei from '../images/zhengwei.jpg'




class Report extends React.Component {
    onClick(key) {
        this.props.changeselectedkeys([key])
    }
    render() {
        const data = ['多多观察', '媒体报道']

        return (
            <div className={styles.newscontent}>
                <Row gutter={24}>
                    <Col span={6} className={styles.newssidebar}>
                        <Sidebar menudata={data} selectedtitle='媒体报道' onClick={this.onClick.bind(this)} />
                    </Col>
                    <Col span={8} className={styles.box}>
                        <div>
                            <ImgMask Img={zhengwei} newtab={true} title="专访 | 车多多入场 二手车行业即将风云再起" src="http://www.sohu.com/a/239009082_505310" />
                        </div>
                    </Col>
                    <Col span={8} className={styles.box}>
                        <div>
                            <ImgMask Img={caibo} newtab={true} title="车多多COO蔡波：批判的看待二手车市场" src="http://industry.caijing.com.cn/20180702/4479628.shtml" />
                        </div>
                    </Col>
                    <Col span={2}></Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeselectedkeys: bindActionCreators(changeselectedkeys, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Report)