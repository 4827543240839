import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './contacticon.less'


class ContactIcon extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hover: false }
    }
    onhover(bool) {
        this.setState({ hover: bool })
    }
    onClick() {
        this.props.onClick()
    }
    render() {
        const { normalsrc, selectedsrc, title, note, haslink, link, target, imgstyle } = this.props
        const { hover } = this.state
        const normalr = 
            <div onMouseEnter={this.onhover.bind(this, true)}>
                <div className={styles.imgbox}>
                    <img src={normalsrc} alt="" style={imgstyle ? imgstyle:{}} />
                </div>
                <div className={styles.title}>
                    <span>{title}</span>
                </div>
                <div className={styles.note_hide}>
                    <span>{note ? note : ''}</span>
                </div>
            </div>
        const hoverr = 
            <div onMouseLeave={this.onhover.bind(this, false)}>
                <div className={styles.imgbox}>
                    <img src={selectedsrc} alt="" style={imgstyle ? imgstyle : {}} />
                </div>
                <div className={styles.hovertitle}>
                    <span>{title}</span>
                </div>
                <div className={styles.note}>
                    <span>{note ? note:''}</span>
                </div>
            </div>
        const linkr = <a href={link} target={target}>{hoverr}</a>

        return (
            <div className={styles.box}>
                {hover ? haslink ? linkr :hoverr : normalr}
            </div>
        )
    }
}
ContactIcon.propTypes = {
    onClick: PropTypes.func
}


export default connect()(ContactIcon);