import React from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeheaderversion, changeselectedkeys, changeselectedtitle } from '../../action/index/header'
import { changefooterversion, changebearborder } from '../../action/index/footer'
import styles from './news.less'
import Observation from './Observation'
import Report from './Report'





class News extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(true)
        this.props.changefooterversion(true)
        this.props.changebearborder(true)
        this.props.changeselectedtitle('新闻中心')
        const selectedKeys = this.props.match.params.tab
        switch (selectedKeys) {
            case "observation":
                this.props.changeselectedkeys(['多多观察'])
                break;
            case "report":
                this.props.changeselectedkeys(['媒体报道'])
                break;
            default:
                this.props.changeselectedkeys([])
                break;
        }
    }
    render() {
        const { selectedKeys } = this.props.header
        return (
            <div>
                <Row type="flex" justify="center" className={styles.news} style={{ "minHeight": 'calc(100vh - 127px)'}}>
                    <Col span={2}>
                    </Col>
                    <Col span={20}>
                        {selectedKeys[0] === "媒体报道" ? <Report /> : <Observation />}
                    </Col>
                    <Col span={2}>
                    </Col>
                </Row>
            </div>
        )
    }
}
function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changebearborder: bindActionCreators(changebearborder, dispatch),
        changeselectedkeys: bindActionCreators(changeselectedkeys, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(News)