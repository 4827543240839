import React from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeheaderversion, changeselectedtitle } from '../../action/index/header'
import { changefooterversion, changebearborder } from '../../action/index/footer'
import Carousels from '../common/Carousels'
import ContactIcon from '../common/ContactIcon'

import banner from '../images/contact_banner.jpg'
import join_in from '../images/contact_join_in.png'
import join_in_selected from '../images/contact_join_in_selected.png'
import address_beijing_normal from '../images/address_beijing_normal.png'
import address_beijing_selected from '../images/address_beijing_selected.png'
import address_hangzhou_normal from '../images/address_hangzhou_normal.png'
import address_hangzhou_selected from '../images/address_hangzhou_selected.png'
import business_normal from '../images/business_normal.png'
import business_selected from '../images/business_selected.png'
import joinus_normal from '../images/joinus_normal.png'
import joinus_selected from '../images/joinus_selected.png'
import contact_logo from '../images/contact_logo.png'
import styles from './contact.less'



class Contact extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(true)
        this.props.changefooterversion(true)
        this.props.changebearborder(false)
        this.props.changeselectedtitle('联系我们')
    }
    render() {
        const data = [
            banner
        ]

        return (
            <div className={styles.contact}>
                <Carousels data={data} autoplay={false} />
                {/* <div className={styles.contact_logo}>
                    <img src={contact_logo} alt=""/>
                </div> */}
                <Row className={styles.container}>
                    <Col span={2}></Col>
                    <Col span={20}>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={4}>
                                <ContactIcon 
                                    normalsrc={address_beijing_normal} 
                                    selectedsrc={address_beijing_selected}
                                    title="北京地址"
                                    note="北京市朝阳区双桥路12号院电子城数字新媒体创新产业园5号房屋" />
                            </Col>
                            <Col span={4}>
                                <ContactIcon
                                    normalsrc={address_hangzhou_normal}
                                    selectedsrc={address_hangzhou_selected}
                                    title="杭州地址"
                                    note="浙江省杭州市滨江区星耀城1期3幢2209" />
                            </Col>
                            <Col span={4}>
                                <ContactIcon
                                    normalsrc={joinus_normal}
                                    selectedsrc={joinus_selected}
                                    title="加入我们"
                                    note=""
                                    haslink={true}
                                    link="https://app.mokahr.com/apply/shuaiche#/?anchorName=007&sourceToken=&_k=y189cc"
                                    target="_blank" />
                            </Col>
                            <Col span={4}>
                                <ContactIcon
                                    normalsrc={business_normal}
                                    selectedsrc={business_selected}
                                    title="商务合作"
                                    note="hezuo@cdd.group" />
                            </Col>
                            <Col span={4}>
                                <ContactIcon
                                    normalsrc={join_in}
                                    selectedsrc={join_in_selected}
                                    title="加盟中心"
                                    note=""
                                    imgstyle={{width:'50px'}}
                                    haslink={true}
                                    link="/comingonline"
                                    target="_blank" />
                            </Col>
                            <Col span={2}></Col>
                        </Row>
                    </Col>
                    <Col span={2}></Col>
                </Row>
            </div>
        )
    }
}
function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch),
        changebearborder: bindActionCreators(changebearborder, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contact)