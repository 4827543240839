import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeheaderversion, changeselectedtitle } from '../../action/index/header'
import { changefooterversion, changefooterstyle, changebearborder } from '../../action/index/footer'

import guide_logo from '../images/guide_logo.png'
import guide_word from '../images/guide_word.png'
import guide_d from '../images/guide_d.png'
import styles from './guide.less'



class Guide extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(false)
        this.props.changefooterversion(true)
        this.props.changebearborder(true)
        this.props.changefooterstyle('bg_white')
        // this.props.changeselectedtitle('联系我们')
    }
    render() {

        return (
            <div>
                <a href="/index">
                    <div className={styles.guide}>
                        <span className={styles.dbox}>
                            <img src={guide_d} alt="" />
                        </span>
                        <span className={styles.logobox}>
                            <img src={guide_logo} alt=""/>
                        </span>
                        {/* <span className={styles.wordbox}>
                            <img src={guide_word} alt=""/>
                        </span> */}
                    </div>
                </a>
            </div>
        )
    }
}
function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch),
        changebearborder: bindActionCreators(changebearborder, dispatch),
        changefooterstyle: bindActionCreators(changefooterstyle, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Guide)