import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeheaderversion, changeselectedtitle } from '../../action/index/header'
import { changefooterversion } from '../../action/index/footer'

import styles from './contact.less'



class Comingonline extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(false)
        this.props.changefooterversion(false)
        this.props.changeselectedtitle('联系我们')
    }
    render() {

        return (
            <div className={styles.coming_online}>
                <div className={styles.text_box}>
                    <div className={styles.text}>即将上线  敬请期待</div>
                    <div className={styles.dot}>...</div>
                </div>
            </div>
        )
    }
}
function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Comingonline)