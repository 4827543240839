var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".container {\n  margin-top: 100px;\n  margin-bottom: 146px;\n}\n.contact_logo {\n  position: absolute;\n  top: calc(106px + 5vh);\n  right: 0;\n}\n.contact_logo img {\n  width: 18.8vw;\n  min-width: 230px;\n  margin-right: 24vw;\n}\n.coming_online {\n  background: url(" + escape(require("../images/coming_online.png")) + ") no-repeat;\n  background-size: 100% 100%;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  text-align: center;\n}\n.coming_online .text_box {\n  position: absolute;\n  left: calc(50% - 161.5px);\n  top: calc(50% - 27px);\n}\n.coming_online .text_box .text {\n  font-size: 46px;\n  font-family: SourceHanSansCN-Light;\n  font-weight: bold;\n  color: #000000;\n  line-height: 34px;\n}\n.coming_online .text_box .dot {\n  font-size: 34px;\n  font-family: SourceHanSansCN-Regular;\n  font-weight: bold;\n  color: #bd081c;\n  line-height: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "container",
	"contact_logo": "contact_logo",
	"coming_online": "coming_online",
	"text_box": "text_box",
	"text": "text",
	"dot": "dot"
};