exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".logo {\n  background: #BD081C;\n  width: 114px;\n  height: 57px;\n  line-height: 51.3px;\n  text-align: center;\n  vertical-align: middle;\n}\n.logo img {\n  width: 75%;\n}\n", ""]);

// exports
exports.locals = {
	"logo": "logo"
};