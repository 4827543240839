var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".guide {\n  background: url(" + escape(require("../images/guide_bg.png")) + ") no-repeat;\n  background-size: 100% 100%;\n  position: relative;\n  height: calc(100vh - 70px);\n}\n.guide .dbox {\n  display: inline-block;\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  text-align: center;\n  line-height: calc(100vh - 70px);\n}\n.guide .dbox img {\n  width: 40vw;\n  max-width: 760px;\n  min-width: 620px;\n}\n.guide .logobox {\n  display: inline-block;\n  position: absolute;\n  top: 12.5%;\n  left: 12.5%;\n}\n.guide .wordbox {\n  display: inline-block;\n  position: absolute;\n  width: 930px;\n  left: calc(50% - 465px);\n  top: calc(50% - 50px);\n}\n", ""]);

// exports
exports.locals = {
	"guide": "guide",
	"dbox": "dbox",
	"logobox": "logobox",
	"wordbox": "wordbox"
};