import React from 'react'
import { Row,Col } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeheaderversion, changeselectedtitle } from '../../action/index/header'
import { changefooterversion, changebearborder } from '../../action/index/footer'
import Carousels from '../common/Carousels'

import banner_one from '../images/cxj_banner_one.png'
import banner_two from '../images/cxj_banner_two.png'
import banner_three from '../images/cxj_banner_three.png'
import banner_four from '../images/cxj_banner_four.png'
import banner_five from '../images/cxj_banner_five.png'
import word_icon from '../images/word_icon.png'
import icon_one from '../images/cxj_icon_one.png'
import icon_two from '../images/cxj_icon_two.png'
import icon_three from '../images/cxj_icon_three.png'
import icon_four from '../images/cxj_icon_four.png'
import title_icon from '../images/title_icon.png'
import partner from '../images/partner.jpg'
import partner_one from '../images/cxj_partner_one.png'
import partner_two from '../images/cxj_partner_two.png'
import partner_three from '../images/cxj_partner_three.png'
import partner_four from '../images/cxj_partner_four.png'
import partner_five from '../images/cxj_partner_five.png'
import cxj_partner_title from '../images/cxj_partner_title.png'
import logo from '../images/cxj_logo_red.png'
import slogan from '../images/cxj_slogan.png'
import cxj_qykxc from '../images/cxj_qykxc.png'
import pay from '../images/cxj_pay.png'
import audit from '../images/cxj_audit.png'
import car from '../images/cxj_car.png'
import cxj_y from '../images/cxj_yellow.png'
import styles from './cxj.less'



class Cxj extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(true)
        this.props.changefooterversion(true)
        this.props.changebearborder(true)
        this.props.changeselectedtitle('车小集')
    }
    render() {
        const data = [
            banner_one,
            banner_two,
            banner_three,
            banner_four,
            banner_five
        ]

        return (
            <div>
                <Carousels data={data} autoplay={true} />
                <Row className={styles.containerbox}>
                    <Col span={2}></Col>
                    <Col span={20} className={styles.cxj_box}>
                        <div className={styles.titlebox}>
                            <div className={styles.img_box}>
                                {/* <span>
                                    <img src={logo} alt=""/>
                                </span> */}
                                {/* <span>
                                    <img src={title_icon} alt="" />
                                </span> */}
                                <span>
                                    {/* <span>低价好车</span>
                                    <span>触手可及</span>  */}
                                    <img src={slogan} alt=""/>
                                </span>
                                {/* <span>
                                    <img src={title_icon} alt="" />
                                </span> */}
                            </div>
                            {/* <div className={styles.word}>
                                车小集是一项新车直租业务，志在打造“打车”和“购买”之外的第三种出行方式，解决打车打不到和买车变车奴的问题，以“千元开新车”为核心特点，主张“花更少的钱，开更好的车”，长分期、低月供让用户无须承担过高还款压力，乐享品质生活。车小集，让出行更简单。
                            </div> */}
                            <div className={styles.word}>
                                <span>
                                    <img src={word_icon} alt=""/>
                                </span>
                                车小集以“创造最省心的出行方式”为核心理念，志在打造“打车”和“购买”之外的第三种出行方式，解决“打车打不到”和“买车变车奴”的问题。
                            </div>
                            <div className={styles.word}>
                                <span>
                                    <img src={word_icon} alt="" />
                                </span>
                                产品以“千元开新车”为主要特点，主张“花更少的钱，开更好的车”，长分期、低月供让用户轻松无忧。尾款灵活、可买可换，全程提供贴心车管家服务，让用户永远开新车，乐享品质生活。
                            </div>
                            <div className={styles.word}>
                                <span>
                                    <img src={word_icon} alt="" />
                                </span>
                                车小集，让出行更简单。
                            </div>
                        </div>
                        <div className={styles.container}>
                            <div className={styles.img_box}>
                                <span>
                                    <img src={cxj_qykxc} alt=""/>
                                </span>
                                {/* <span>
                                    <img src={title_icon} alt="" />
                                </span>
                                <span className={styles.text}>
                                    千元开新车
                                </span>
                                <span>
                                    <img src={title_icon} alt="" />
                                </span> */}
                            </div>
                            {/* <div className={styles.img_box}>
                                <span>
                                    <img src={cxj_y} alt=""/>
                                </span>
                            </div> */}
                            <Row>
                                <Col span={12} className={styles.cxj_col}>
                                    <div className={styles.title}>
                                        <div>
                                            <img src={icon_one} alt="" />
                                        </div>
                                        <div className={styles.titletext}>
                                            超低首付
                                        </div>
                                    </div>
                                    <div className={styles.text}>
                                        <span>
                                            首付为成交价的10%；
                                        </span>
                                        <span>
                                            0保证金、含购置税，送首年保险；
                                        </span>
                                        <span>
                                            成交价10万元汽车，首付仅需1万元
                                        </span>
                                    </div>
                                </Col>
                                <Col span={12} className={styles.cxj_col}>
                                    <div className={styles.title}>
                                        <div>
                                            <img src={icon_two} alt="" />
                                        </div>
                                        <div className={styles.titletext}>
                                            极简手续
                                        </div>
                                    </div>
                                    <div className={styles.text}>
                                        <span>
                                            持身份证申请，全程线上操作；
                                        </span>
                                        <span>
                                            预审结果秒出，30分钟过审
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} className={styles.cxj_col}>
                                    <div className={styles.title}>
                                        <div>
                                            <img src={icon_three} alt="" />
                                        </div>
                                        <div className={styles.titletext}>
                                            闪电提车
                                        </div>
                                    </div>
                                    <div className={styles.text}>
                                        <span>
                                            全程代办，店内交车；
                                        </span>
                                        <span>
                                            1-2天提车，最快当天交付
                                        </span>
                                    </div>
                                </Col>
                                <Col span={12} className={styles.cxj_col}>
                                    <div className={styles.title}>
                                        <div>
                                            <img src={icon_four} alt="" />
                                        </div>
                                        <div className={styles.titletext}>
                                            贴心管家
                                        </div>
                                    </div>
                                    <div className={styles.text}>
                                        <span>
                                            提供保险、维修等增值服务；
                                        </span>
                                        <span>
                                            尾款灵活，可买可换
                                        </span>
                                        <span>
                                            永远开新车
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row gutter={36}>
                                <Col span={8}>
                                    <div className={styles.title}>
                                        <span>
                                            <img src={pay} alt=""/>
                                        </span>
                                        <span className={styles.titletext}>
                                            首付低至10% 
                                        </span>
                                    </div>
                                    <div className={styles.text}>
                                        10%首付是指真实成交价的10%，0保证金、包购置税，送首年保险。以成交价10万元汽车为例，用户支付1万元即可。
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className={styles.title}>
                                        <span>
                                            <img src={audit} alt="" />
                                        </span>
                                        <span className={styles.titletext}>
                                            30分钟过审
                                        </span>
                                    </div>
                                    <div className={styles.text}>
                                        用户仅需在线输入身份证件、手机号码即可在线发起申请，最快30分钟即可出具审核结果。
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className={styles.title}>
                                        <span>
                                            <img src={car} alt=""/>
                                        </span>
                                        <span className={styles.titletext}>
                                            1-7天提车
                                        </span>
                                    </div>
                                    <div className={styles.text}>
                                        通常情况下，1-7个工作日即可提车，最快当天交付。
                                    </div>
                                </Col>
                            </Row> */}
                        </div>
                    </Col>
                    <Col span={2}></Col>
                </Row>
                <Row>
                    <Col span={2}></Col>
                    <Col span={20} className={styles.partner}>
                        <div>
                            {/* 战略合作 */}
                            <span>
                                <img src={cxj_partner_title} style={{height:'28px'}} alt="cxj_partner_title"/>
                            </span>
                        </div>
                        <div>
                            {/* <span>
                                <img src={partner} alt=""/>
                            </span> */}
                            <span><img src={partner_one} alt=""/></span>
                            <span><img src={partner_two} alt=""/></span>
                            <span><img src={partner_three} alt=""/></span>
                            <span><img src={partner_four} alt=""/></span>
                            <span><img src={partner_five} alt=""/></span>
                        </div>
                    </Col>
                    <Col span={2}></Col>
                </Row>
            </div>
        )
    }
}
function mapStateToProps({ header }) {
    return {
        header: header,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changebearborder: bindActionCreators(changebearborder, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cxj)