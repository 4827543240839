import React from 'react'
import { Route } from 'react-router-dom'

import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Index from './components/index/Index'
import Guide from './components/index/Guide'
import News from './components/news/News'
import Brand from './components/brand/Brand'
import Cxj from './components/cxj/Cxj'
import Contact from './components/contact/Contact'
import Comingonline from './components/contact/Comingonline'
import Search from './components/search/Search'
import Noresult from './components/search/Noresult'


class App extends React.Component {
  render() {
    return (
      <div>
        <Route component={Header} />
        <Route path="/" exact component={Guide}></Route>
        <Route path="/index" component={Index}></Route>
        <Route path="/news/:tab" component={News}></Route>
        <Route path="/brand" component={Brand}></Route>
        <Route path="/cxj" component={Cxj}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/comingonline" component={Comingonline}></Route>
        <Route path="/search" component={Search}></Route>
        <Route path="/noresult" component={Noresult}></Route>
        <Route component={Footer} />
      </div>
    )
  }
}

export default App