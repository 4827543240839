import { handleActions } from 'redux-actions';

const footer = handleActions({
    ['SET/FOOTER/SELECTEDICON'](state, action) {
        return { ...state, selectedIcon: action.selectedIcon, };
    },
    ['SET/FOOTER/VERSION'](state, action) {
        return { ...state, version: action.version, };
    },
    ['SET/FOOTER/STYLE'](state, action) {
        return { ...state, style: action.style, };
    },
    ['SET/FOOTER/BEARBORDER'](state, action) {
        return { ...state, bear_border: action.bear_border, };
    }
}, {
        selectedIcon: '',
        version: false,
        bear_border: true,
        style: 'bg_red'
    });

export default footer;