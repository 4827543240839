import React from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeheaderversion, changeselectedkeys, changeselectedtitle } from '../../action/index/header'
import { changefooterversion, changebearborder } from '../../action/index/footer'
import { showvideo } from '../../action/brand/brand'
import Sidebar from '../common/Sidebar'
import ImgChange from '../common/ImgChange'
import styles from './brand.less'
import play from '../images/play.gif'
import D from '../images/D.png'
import playpng from '../images/play.png'
import downloadnormal from '../images/download_normal.gif'
import downloadhover from '../images/download_normal.png'
import file from '../files/file.zip'
import video from '../video/MG.mp4'
import duoyouxuanone from '../images/cxj_duoyouxuan_one.png'
import duoyouxuantwo from '../images/cxj_duoyouxuan_two.png'





class Brand extends React.Component {
    componentWillMount() {
        this.props.changeheaderversion(true)
        this.props.changefooterversion(true)
        this.props.changebearborder(true)
        this.props.changeselectedtitle('品牌')
    }
    showVideo(bool){
        this.props.showvideo(bool)
        if(bool){
            this.refs['video'].play()
        }else{
            this.refs['video'].pause()
        }
    }
    download(sUrl) {
        const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
        const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
        //iOS devices do not support downloading. We have to inform user about this.
        if (/(iP)/g.test(navigator.userAgent)) {
            alert('Your device does not support files downloading. Please try again in desktop browser.');
            return false;
        }

        //If in Chrome or Safari - download via virtual link click
        if (isChrome || isSafari) {
            //Creating new link node.
            var link = document.createElement('a');
            link.href = sUrl;

            if (link.download !== undefined) {
                //Set HTML5 download attribute. This will prevent file from opening if supported.
                var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
                link.download = fileName;
            }

            //Dispatching click event.
            if (document.createEvent) {
                var e = document.createEvent('MouseEvents');
                e.initEvent('click', true, true);
                link.dispatchEvent(e);
                return true;
            }
        }

        // Force file download (whether supported by server).
        if (sUrl.indexOf('?') === -1) {
            sUrl += '?download';
        }
        window.open(sUrl, '_self');
        return true;
    }

    render() {
        const { selectedKeys } = this.props.header
        const { videovesrion } = this.props.brand
        const data = ['品牌宣传片', '公司简介','品牌理念','多优选','资料下载']
        return (
            <div>
                <Row type="flex" justify="center" className={styles.brand} style={{ "minHeight": document.body.clientHeight - 127 }}>
                    <Col span={2}>
                    </Col>
                    <Col span={20}>
                        <Row gutter={24} className={styles.brandcontent}>
                            <Col span={6} className={styles.brandsidebar}>
                                <Sidebar menudata={data} selectall={true} />
                            </Col>
                            <Col span={16} className={styles.box}>
                                <Row className={styles.videostart}>
                                    <Col span={12} className={styles.videoplay}>
                                        <ImgChange normalsrc={play} selectedsrc={playpng} onClick={this.showVideo.bind(this,true)} />
                                    </Col>
                                    <Col span={12} className={styles.wordbox}>
                                        <div className={styles.word}>
                                            <div>品牌宣传片</div>
                                            <div>BRAND PROMOTION VIDEO</div>
                                        </div>
                                        <div className={styles.button}>
                                            <span>点击查看</span>
                                        </div>
                                    </Col>
                                </Row>
                                <div className={styles.container}>
                                    <div className={styles.titlebox}>
                                        <div className={styles.title}>公司简介</div>
                                        <div className={styles.redbar}></div>
                                    </div>
                                    <div className={styles.contentbox}>
                                        <span>
                                            <img src={D} alt=""/>
                                        </span>
                                        <span>
                                            车多多是一家致力于构建汽车交易‘新服务’生态的科技企业。以“为消费者提供安心、便捷的购车体验”为使命，致力于通过科技创新及专业服务，搭建一个开放、创新、务实的交易服务平台。
                                        </span>
                                    </div>
                                    {/* <div className={styles.contentbox}>
                                        <span className={styles.text_2em}>
                                            公司旗下拥有二手车金融服务品牌[车多多]及新车融资租赁产品[车小集]。车多多通过整合上游产品建立‘金融产品超市’为二手车经销商提供丰富的金融解决方案；车小集是针对年轻消费者提供的‘先用后买’低首付、低月供的金融产品方案。
                                        </span>
                                    </div> */}
                                </div>
                                <div className={styles.container}>
                                    <div className={styles.titlebox}>
                                        <div className={styles.title}>品牌理念</div>
                                        <div className={styles.redbar}></div>
                                    </div>
                                    <div className={styles.contentbox}>
                                        <span>
                                            <img src={D} alt="" />
                                        </span>
                                        <span>
                                            车多多作为二手车交易“新服务”生态体系，寓意好车多多、产品多多、服务多多、幸福多多，通过汇聚海量真实车源和丰富的金融产品，依托AI、大数据、云计算等技术，提供带看、分期、保险、延保、评估、检测、过户、物流等多种服务，创造无限购车可能，让出行更美好。
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.container}>
                                    <div className={styles.titlebox}>
                                        <div className={styles.title}>多优选</div>
                                        <div className={styles.redbar}></div>
                                    </div>
                                    <div className={styles.contentbox}>
                                        <span>
                                            <img src={D} alt="" />
                                        </span>
                                        <span>
                                        北京好车多多科技有限公司，简单放心购买二手车。多优选具体包含“交易平台、金融科技、车务管家、保险智选”，致力于做好——“保险经纪；保险咨询；分期付款的贷款；债务托收代理；抵押贷款；融资租赁；通过网站提供金融信息；陆地车辆赊售（融资租赁）；典当经纪”等服务项目。
                                        </span>
                                    </div>
                                    <div className={styles.duoyouxuanimg}>
                                        <img src={duoyouxuanone} alt="" />
                                        <img src={duoyouxuantwo} alt="" />
                                    </div>
                                </div>
                                <Row className={styles.downloadbox}>
                                    <Col span={12} className={styles.downloadimgbox}>
                                        <ImgChange normalsrc={downloadnormal} selectedsrc={downloadhover} onClick={this.download.bind(this, file)} />
                                    </Col>
                                    <Col span={12} className={styles.wordbox}>
                                        <div className={styles.word}>
                                            <div>媒体资料下载</div>
                                            <div>MEDIA INFORMATION</div>
                                        </div>
                                        <div className={styles.button} onClick={this.download.bind(this, file)}>
                                            <span>← &nbsp; download</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}></Col>
                        </Row>
                    </Col>
                    <Col span={2}>
                    </Col>
                    <div className={styles.fullmask} style={{ "display": videovesrion?'block':'none'}} onClick={this.showVideo.bind(this,false)}>
                        <div className={styles.video}>
                            <video id="video" width="1190" ref="video" height="670" src={video} controls="controls"></video>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
}
function mapStateToProps({ header,brand }) {
    return {
        header: header,
        brand: brand
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeheaderversion: bindActionCreators(changeheaderversion, dispatch),
        changefooterversion: bindActionCreators(changefooterversion, dispatch),
        changeselectedtitle: bindActionCreators(changeselectedtitle, dispatch),
        changeselectedkeys: bindActionCreators(changeselectedkeys, dispatch),
        changebearborder: bindActionCreators(changebearborder, dispatch),
        showvideo: bindActionCreators(showvideo,dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Brand)