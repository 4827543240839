exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imgbox {\n  width: 100%;\n  position: relative;\n}\n.imgbox a,\n.imgbox a:hover,\n.imgbox a:visited,\n.imgbox a:link {\n  text-decoration: none;\n  color: #FFF;\n}\n.imgbox img {\n  width: 100%;\n  height: auto;\n}\n.imgbox .mask {\n  position: absolute;\n  border: 1px solid #fff;\n  background: rgba(0, 0, 0, 0.8);\n  top: 0px;\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n}\n.imgbox .mask div {\n  position: absolute;\n  padding-left: 10%;\n  bottom: 10%;\n}\n", ""]);

// exports
exports.locals = {
	"imgbox": "imgbox",
	"mask": "mask"
};