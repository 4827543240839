import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './imgmask.less'


class ImgMask extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hover: false }
    }
    onhover(bool) {
        this.setState({hover:bool})
    }
    render() {
        const { Img, title, src, newtab } = this.props
        const {hover} = this.state
        const normalr = <div onMouseEnter={this.onhover.bind(this,true)}><img src={Img} alt={title} /></div>
        const hoverr = <div onMouseLeave={this.onhover.bind(this, false)}>
            <a href={src} target={newtab ? "_blank" :"_self"}>
                <img src={Img} alt={title}/>
                <div className={styles.mask}>
                    <div>
                        <span>{title}</span>
                    </div>
                </div>
            </a>
        </div>
        
        return (
            <div className={styles.imgbox}>
                {hover ? hoverr : normalr}
            </div>
        )
    }
}
ImgMask.propTypes = {
    menudata: PropTypes.array,
    selectedtitle: PropTypes.string,
    fixed: PropTypes.bool,
    onClick: PropTypes.func
}


export default connect()(ImgMask);