//搜索
export function changekeyword(keyword) {
    return {
        type: 'SET/SEARCH/KEYWORD',
        keyword: keyword,
    }
}
export function changeresult(result) {
    return {
        type: 'SET/SEARCH/RESULT',
        result: result,
    }
}