exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".box {\n  position: relative;\n}\n.box .imgbox {\n  text-align: center;\n  min-height: 54px;\n}\n.box .imgbox > img {\n  width: 64px;\n  height: auto;\n  max-height: 54px;\n}\n.box .title {\n  text-align: center;\n  margin-top: 8px;\n}\n.box .title span {\n  background: #adadad;\n  border-radius: 10px;\n  display: inline-block;\n  width: 90px;\n  font-size: 12px;\n  font-family: MicrosoftYaHeiLight;\n  font-weight: bold;\n  color: #ffffff;\n  line-height: 22px;\n}\n.box .hovertitle {\n  text-align: center;\n  margin-top: 8px;\n}\n.box .hovertitle span {\n  background: #BD081C;\n  border-radius: 10px;\n  display: inline-block;\n  width: 90px;\n  font-size: 12px;\n  font-family: MicrosoftYaHeiLight;\n  font-weight: bold;\n  color: #ffffff;\n  line-height: 22px;\n}\n.box .note_hide {\n  text-align: center;\n  font-size: 14px;\n  font-family: MicrosoftYaHeiLight;\n  font-weight: bold;\n  color: #000000;\n  line-height: 48px;\n  visibility: hidden;\n}\n.box .note {\n  text-align: center;\n  font-size: 14px;\n  font-family: MicrosoftYaHeiLight;\n  font-weight: bold;\n  color: #000000;\n  line-height: 48px;\n}\n", ""]);

// exports
exports.locals = {
	"box": "box",
	"imgbox": "imgbox",
	"title": "title",
	"hovertitle": "hovertitle",
	"note_hide": "note_hide",
	"note": "note"
};