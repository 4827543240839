exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".resultbox {\n  text-align: left;\n}\n.resultbox .result a,\n.resultbox .result a:hover,\n.resultbox .result a:link,\n.resultbox .result a:visited {\n  font-size: 20px;\n  font-family: SourceHanSansCN-Normal;\n  font-weight: bold;\n  color: #000000;\n  line-height: 40px;\n  text-decoration: underline;\n}\n", ""]);

// exports
exports.locals = {
	"resultbox": "resultbox",
	"result": "result"
};